export const environment = {
  origin: 'https://sandbox.cargotenders.com',
  name: 'sandbox',
  production: false,
  is_local: false,
  plugins: [],
  api_domain: 'sandbox-be.cargotenders.com',
  api_url: 'https://sandbox-be.cargotenders.com/api/v1',
  mercure_url: 'https://sandbox-ws.cargotenders.com',
  sentry_dsn: null,
  enableLogger: false,
  gis_map_api_key: '00b7d46c-70a6-41cd-a351-4d8fae1431c0',
};
